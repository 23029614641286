import React from 'react';
import { unstable_getServerSession } from 'next-auth/next';
import { authOptions } from 'pages/api/auth/[...nextauth]';
import { GetSubSceneIdByDomainDocument } from '@src/components/generated/graphql';
import { DEFAULT_SCENE_ID } from 'util/constants';
import { initializeApollo } from '../src/apolloClient';
import QuestSystemWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/QuestSystemWidget';
import CheckoutWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/CheckoutWidget';
import NftAvatarsWidget from '@ozoneuniverse/ui-components/build-pkg/src/components/studio-widgets/NftAvatarsWidget';
import PortalWidget from '@src/components/scene/widgets/PortalWidget';
import ChatWidget from '@src/components/scene/chat/ChatWidget';
import dynamic from 'next/dynamic';
import ScanWidget from '@src/components/scene/scan/ScanWidget';

const client = initializeApollo();

export async function getServerSideProps(context) {
  const session = await unstable_getServerSession(
    context.req,
    context.res,
    authOptions
  );

  const host = context.req.headers.host;
  const subSceneRoute = context.req.url.split('/')[1];
  let sceneId = DEFAULT_SCENE_ID;

  try {
    const res = await client.query({
      query: GetSubSceneIdByDomainDocument,
      variables: { where: { domain: host, route: subSceneRoute } },
      fetchPolicy: 'no-cache',
    });

    if (res?.data?.getSubSceneIdByDomain.sceneId) {
      sceneId = res?.data?.getSubSceneIdByDomain.sceneId;
    }
  } catch (e) {
    console.error(e);
  }

  return {
    props: {
      isSignIn: session ? true : false,
      userId: session?.user.id ?? '',
      userEmail: session?.user.email ?? '',
      sceneId,
    },
  };
}

const ComponentWithNoSSR = dynamic(
  () =>
    import(
      '@ozoneuniverse/ui-components/build-pkg/src/components/scene/publicScene'
    ),
  {
    ssr: false,
  }
);

export function Scene(props) {
  return (
    <>
      <div id='local-media'></div>
      <div id='webrtc'>
        <div id='participants'>
          <div id='remote-media'></div>
        </div>
        <div id='room-controls'>
          <button id='button-join'>Join Room</button>
          <button id='button-leave'>Leave Room</button>
          <button id='sound-track'>Mute</button>
          <button id='video-track'>Disable video</button>
          <input id='upload-image' type='file' />
        </div>

        <button id='start_share'>Start Capture</button>
        <button id='stop_share'>Stop Capture</button>

        <video id='video_share' autoPlay></video>
        <strong>Log:</strong>
        <br />
        <pre id='log'></pre>
      </div>
      <ComponentWithNoSSR
        userId={props.userId}
        userEmail={props.userEmail}
        isSignIn={props.isSignIn}
        sceneId={props.sceneId}
      >
        <CheckoutWidget />
        <QuestSystemWidget />
        <PortalWidget />
        <NftAvatarsWidget />
        <ChatWidget sceneId={props.sceneId} />
        <ScanWidget sceneId={props.sceneId} />
      </ComponentWithNoSSR>
    </>
  );
}
export default Scene;
